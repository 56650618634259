var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":10,"calculate-widths":"","loading":_vm.loading_status,"loader-height":"10","loading-text":"Cargando ...","dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('h3',[_vm._v("Rotación por Marca")]),_c('v-spacer')],1),_c('v-row',{staticClass:"pa-2"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Desde","hide-details":"auto","required":"","dense":"","outlined":"","type":"date"},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Hasta","hide-details":"auto","required":"","dense":"","outlined":"","type":"date"},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.get_report()}}},[_vm._v("Buscar")])],1)],1)]},proxy:true},{key:"item.categoria",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.categoria + " / " + item.grupo))])]}},{key:"item.brand",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.brand))])]}},{key:"item.inv_i",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(parseFloat(item.inv_i).toLocaleString(2)))])]}},{key:"item.qty_ingresos",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(parseFloat(item.qty_ingresos).toLocaleString(2)))])]}},{key:"item.qty_sales",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(parseFloat(item.qty_sales).toLocaleString(2)))])]}},{key:"item.inv_f",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(parseFloat(item.inv_f).toLocaleString(2)))])]}},{key:"item.turnover",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(parseFloat(item.turnover).toLocaleString(6)))])]}},{key:"item.roi",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(parseFloat(item.roi).toLocaleString(6)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.showBrand(item)}}},[_vm._v(" mdi-magnify ")])]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_vm._v("Total")]),_c('th'),_c('th'),_c('th'),_c('th'),_c('th',{staticClass:"text-right "},[_vm._v(_vm._s(_vm.sumTable('items', 'ventas')))]),_c('th'),_c('th',{staticClass:"text-right "},[_vm._v(_vm._s(_vm.sumTable('items', 'cantidad')))])])])],2),_c('v-dialog',{attrs:{"transition":"dialog-fade-transition","scrollable":"","max-width":"800px","fullscreen":_vm.$vuetify.breakpoint.smAndDown},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"flat":""}},[_vm._v(" Rotación por referencia "),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_vm._v(" mdi-close ")])],1)],1),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers_brand,"items":_vm.items_brand,"items-per-page":10,"calculate-widths":"","search":_vm.search,"loading":_vm.loading_status,"loader-height":"10","loading-text":"Cargando ...","dense":""},scopedSlots:_vm._u([{key:"item.categoria",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.categoria + " / " + item.grupo))])]}},{key:"item.brand",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.brand))])]}},{key:"item.inv_i",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(parseFloat(item.inv_i).toLocaleString(2)))])]}},{key:"item.qty_ingresos",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(parseFloat(item.qty_ingresos).toLocaleString(2)))])]}},{key:"item.qty_sales",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(parseFloat(item.qty_sales).toLocaleString(2)))])]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_vm._v("Total")]),_c('th'),_c('th'),_c('th'),_c('th'),_c('th'),_c('th',{staticClass:"text-right "},[_vm._v(_vm._s(_vm.sumTable('items_brand', 'ventas')))]),_c('th'),_c('th',{staticClass:"text-right "},[_vm._v(_vm._s(_vm.sumTable('items_brand', 'cantidad')))])])])],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }