<template>
    <v-container fluid>
        <v-data-table :headers="headers" :items="items" :items-per-page="10" calculate-widths :loading="loading_status"
            loader-height="10" loading-text="Cargando ..." dense>
            <template #top>
                <v-toolbar flat>
                    <h3>Rotación por Marca</h3>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-row class="pa-2">
                    <v-col cols="12" md="3">
                        <v-text-field v-model="dateFrom" label="Desde" hide-details="auto" required dense outlined
                            type="date" />
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field v-model="dateTo" label="Hasta" hide-details="auto" required dense outlined
                            type="date" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-btn color="success" @click="get_report()">Buscar</v-btn>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:[`item.categoria`]="{ item }">
                <span>{{ item.categoria + " / " + item.grupo }}</span>
            </template>
            <template v-slot:[`item.brand`]="{ item }">
                <span>{{ item.brand }}</span>
            </template>
            <template v-slot:[`item.inv_i`]="{ item }">
                <span>{{ parseFloat(item.inv_i).toLocaleString(2) }}</span>
            </template>
            <template v-slot:[`item.qty_ingresos`]="{ item }">
                <span>{{ parseFloat(item.qty_ingresos).toLocaleString(2) }}</span>
            </template>
            <template v-slot:[`item.qty_sales`]="{ item }">
                <span>{{ parseFloat(item.qty_sales).toLocaleString(2) }}</span>
            </template>
            <template v-slot:[`item.inv_f`]="{ item }">
                <span>{{ parseFloat(item.inv_f).toLocaleString(2) }}</span>
            </template>
            <template v-slot:[`item.turnover`]="{ item }">
                <span>{{ parseFloat(item.turnover).toLocaleString(6) }}</span>
            </template>
            <template v-slot:[`item.roi`]="{ item }">
                <span>{{ parseFloat(item.roi).toLocaleString(6) }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon class="mr-2" @click="showBrand(item)">
                    mdi-magnify
                </v-icon>
            </template>
            <template slot="body.append">
                <tr>
                    <th>Total</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th class="text-right ">{{ sumTable('items', 'ventas') }}</th>
                    <th></th>
                    <th class="text-right ">{{ sumTable('items', 'cantidad') }}</th>

                </tr>
            </template>

        </v-data-table>
        <v-dialog v-model="dialog" transition="dialog-fade-transition" scrollable max-width="800px"
            :fullscreen="$vuetify.breakpoint.smAndDown">
            <v-card>
                <v-card-title class="pa-0">
                    <v-toolbar flat>
                        Rotación por referencia
                        <v-spacer></v-spacer>
                        <v-icon @click="dialog = !dialog"> mdi-close </v-icon>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="pa-0">
                    <v-data-table :headers="headers_brand" :items="items_brand" :items-per-page="10" calculate-widths
                        :search="search" :loading="loading_status" loader-height="10" loading-text="Cargando ..." dense>

                        <template v-slot:[`item.categoria`]="{ item }">
                            <span>{{ item.categoria + " / " + item.grupo }}</span>
                        </template>
                        <template v-slot:[`item.brand`]="{ item }">
                            <span>{{ item.brand }}</span>
                        </template>
                        <template v-slot:[`item.inv_i`]="{ item }">
                            <span>{{ parseFloat(item.inv_i).toLocaleString(2) }}</span>
                        </template>
                        <template v-slot:[`item.qty_ingresos`]="{ item }">
                            <span>{{ parseFloat(item.qty_ingresos).toLocaleString(2) }}</span>
                        </template>
                        <template v-slot:[`item.qty_sales`]="{ item }">
                            <span>{{ parseFloat(item.qty_sales).toLocaleString(2) }}</span>
                        </template>

                        <template slot="body.append">
                            <tr>
                                <th>Total</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th class="text-right ">{{ sumTable('items_brand', 'ventas') }}</th>
                                <th></th>
                                <th class="text-right ">{{ sumTable('items_brand', 'cantidad') }}</th>


                            </tr>
                        </template>

                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { webserver, getToday, getdays_ago } from "../services/webserver.js";

export default {
    components: {},
    data() {
        return {
            errors: [],
            valid: false,
            loading_status: false,
            f_required: [(v) => !!v || "Requerido"],
            f_email: [
                (v) => !!v || "Correo valido",
                (v) => /.+@.+/.test(v) || "Correo no valido",
            ],
            imageUrl: null,
            dialog: false,
            headers: [
                {
                    text: "Categoría",
                    align: "start",
                    sortable: true,
                    value: "category",
                    dataType: "text",
                },
                {
                    text: "Grupo",
                    align: "start",
                    sortable: true,
                    value: "group",
                    dataType: "text",
                },
                {
                    text: "Marca",
                    align: "start",
                    sortable: true,
                    value: "brand",
                    dataType: "text",
                },
                {
                    text: "Refencia",
                    align: "start",
                    sortable: true,
                    value: "Refe",
                    dataType: "text",
                },
                {
                    text: "Color",
                    align: "start",
                    sortable: true,
                    value: "colorName",
                    dataType: "text",
                },
                {
                    text: "Ventas",
                    align: "end",
                    sortable: true,
                    value: "ventas",
                    dataType: "text",
                },
                {
                    text: "vdp",
                    align: "end",
                    sortable: true,
                    value: "vdp",
                    dataType: "text",
                },
                {
                    text: "Cantidad",
                    align: "end",
                    sortable: true,
                    value: "cantidad",
                    dataType: "number",
                },
                { text: "", value: "actions", align: "end", sortable: false },
            ],
            headers_brand: [
                {
                    text: "Categoría",
                    align: "start",
                    sortable: true,
                    value: "category",
                    dataType: "text",
                },
                {
                    text: "Grupo",
                    align: "start",
                    sortable: true,
                    value: "groupName",
                    dataType: "text",
                },
                {
                    text: "Marca",
                    align: "start",
                    sortable: true,
                    value: "brand",
                    dataType: "text",
                },
                {
                    text: "Refencia",
                    align: "start",
                    sortable: true,
                    value: "refe",
                    dataType: "text",
                },
                {
                    text: "Color",
                    align: "start",
                    sortable: true,
                    value: "colorName",
                    dataType: "text",
                },
                {
                    text: "Talla",
                    align: "start",
                    sortable: true,
                    value: "talla",
                    dataType: "text",
                },
                {
                    text: "Ventas",
                    align: "end",
                    sortable: true,
                    value: "ventas",
                    dataType: "text",
                },
                {
                    text: "vdp",
                    align: "end",
                    sortable: true,
                    value: "vdp",
                    dataType: "text",
                },
                {
                    text: "Cantidad",
                    align: "end",
                    sortable: true,
                    value: "cantidad",
                    dataType: "number",
                },
            ],
            items: [],
            sales: [],
            items_brand: [],
            dateFrom: getdays_ago(-60),
            dateTo: getToday(),
            search: '',
        };
    },
    mounted() {
        this.get_report("marca");
    },
    methods: {
        sumTable(table, key) {
            return parseFloat(this[table].reduce((a, b) => a + (parseFloat(b[key]) || 0), 0)).toLocaleString(2)
        },
        showBrand(e) {
            this.items_brand = [];

            this.sales.forEach((itm) => {
                if (itm.marca === e.marca && itm.refe === e.refe && itm.color === e.color) {
                    this.items_brand.push(itm);
                }
            });
            this.dialog = true;
        },
        get_report() {
            this.loading_status = true;
            var qry = {
                store: window.store.store_id,
                issueDate: this.dateFrom,
            };
            webserver("get_stock_rotation_b", qry, (data) => {
                console.log(data)
                var days = 60;
                data.forEach(function (e) {
                    e.cantidad = parseFloat(e.cantidad);
                    e.ventas = parseFloat(e.ventas);
                    e.vdp = (e.ventas / days).toFixed(3);
                });
                this.sales = data;
                data.forEach(function (itm) {
                    itm.category = window.settings["CATEGORIA"].find(item => item.codigo == itm.categoria).valor;
                    itm.groupName = window.settings["GRUPO"].find(item => item.codigo == itm.grupo).valor;
                    itm.brand = window.settings["MARCA"].find(item => item.codigo == itm.marca).valor;
                    itm.colorName = window.settings["COLOR"].find(item => item.codigo == itm.color).valor;
                });
                var helper = {};
                var result = data.reduce(function (r, o) {
                    var key = o.marca + '-' + o.refe + '-' + o.color;
                    if (!helper[key]) {
                        helper[key] = Object.assign({}, o); // create a copy of o
                        r.push(helper[key]);
                    } else {
                        helper[key].ventas += o.ventas;
                        helper[key].cantidad += o.cantidad;
                    }
                    return r;
                }, []);
                result.forEach(function (e) {
                    e.vdp = (e.ventas / days).toFixed(3);
                });
                this.items = result;
                this.loading_status = false;
            });
        },
    },
};
</script>

<style>
</style>
